export const STRING_TYPE = "stringType"
export const ARRAY_OF_OBJECT_TYPE = "arrayOfObjectType"
export const ARRAY_OF_STRING_TYPE = "arrayOfStringType"
export const DATE_TYPE = "dateType"
export const OBJECT_TYPE = "objectType"
export const NUMBER_TYPE = "numberType"
export const AMOUNT_TYPE = "amountType"
export const BOOLEAN_TYPE = "booleanType"
export const NULL_TYPE = "nullType"
export const TEXT_AREA_TYPE = "textAreaType"
export const DROPDOWN_TYPE = "dropdownType"
