import {
  BRANCH_MASTERFILE,
  CUSTOMER_REPORTS,
  DAILY_REPORTS,
  DASHBOARD,
  DIRECT_AND_THIRD_PARTY,
  DISCOUNT_OTHERS,
  DROPDOWN_MASTERFILE,
  FOR_DEPOSITS,
  GRILL_RESERVATION,
  INCIDENT_REPORTS,
  INVENTORY,
  LABEL,
  MASTER_DATA,
  MONTHLY_SALES_SUMMARY,
  PAYMENT_TRANSACTION,
  PRICE_HISTORY_MASTERFILE,
  PRODUCTS_MASTERFILE,
  REPORTS,
  ROLES_MASTERFILE,
  TRANSACTION,
  USER_MASTERFILE,
} from "Constants/pathNames"
import React from "react"
import MainPage from "Pages/MainPage"
import DashboardTransactionPage from "Pages/DashboardTransactionPage"
import {
  DashboardOutlined,
  SettingOutlined,
  FolderOutlined,
} from "@ant-design/icons"

export default function (modules) {
  return [
    {
      title: LABEL[DASHBOARD],
      key: DASHBOARD,
      dataKey: DASHBOARD,
      Icon: <DashboardOutlined />,
      active: true,
      display: modules.includes(LABEL[DASHBOARD]),
      component: MainPage,
      path: "/dashboard",
      imageUrl: "",
      subMenu: [
        {
          title: LABEL[GRILL_RESERVATION],
          key: GRILL_RESERVATION,
          dataKey: GRILL_RESERVATION,
          active: true,
          path: "/dashboard/grillReservation",
          component: MainPage,
          display: modules.includes(LABEL[GRILL_RESERVATION]),
          imageUrl:
            "https://img.freepik.com/free-vector/employee-checking-date-important-event-deadline-calendar-person-holding-pen-flat-vector-illustration-time-management-schedule-agenda-concept-banner-website-design-landing-web-page_74855-24512.jpg?w=1380&t=st=1667323947~exp=1667324547~hmac=51d8fee933abf229e2faf43649627c880ee47a6e5e7bb7308945f4b7acdb0af6",
        },
        {
          title: LABEL[PAYMENT_TRANSACTION],
          key: PAYMENT_TRANSACTION,
          dataKey: PAYMENT_TRANSACTION,
          active: false,
          path: "/dashboard/paymentTransaction",
          component: DashboardTransactionPage,
          display: modules.includes(LABEL[PAYMENT_TRANSACTION]),
          imageUrl:
            "https://img.freepik.com/free-vector/man-paying-online-getting-sales-slip-receipt-laptop-terminal-flat-vector-illustration-payment-money-transaction_74855-13151.jpg?w=996&t=st=1667324275~exp=1667324875~hmac=b1a6965f5842b0463086da81af753133cce09e04d70b57c3e8b04ff06e04032b",
        },
        {
          title: LABEL[FOR_DEPOSITS],
          key: FOR_DEPOSITS,
          dataKey: FOR_DEPOSITS,
          active: false,
          path: "/dashboard/forDeposits",
          // component: MainPage,
          display: modules.includes(LABEL[FOR_DEPOSITS]),
          imageUrl:
            "https://img.freepik.com/free-photo/credit-card-payment-buy-sell-products-service_1150-16379.jpg?w=1380&t=st=1667324668~exp=1667325268~hmac=7910d93493a5dfd1d4bbe4c5089a2c3e4d339bf7c7bf3f4bd388557776261279",
        },
      ],
    },
    {
      title: LABEL[REPORTS],
      key: REPORTS,
      dataKey: REPORTS,
      Icon: <FolderOutlined />,
      active: false,
      flex: 1,
      display: modules.includes(LABEL[REPORTS]),
      path: `/${REPORTS}`,
      component: MainPage,
      imageUrl: "",
      subMenu: [
        // {
        //   title: LABEL[TRANSACTION],
        //   key: TRANSACTION,
        //   dataKey: TRANSACTION,
        //   active: false,
        //   path: "/reports/transaction",
        //   component: MainPage,
        //   display: modules.includes(LABEL[TRANSACTION]),
        // },
        // {
        //   title: LABEL[DIRECT_AND_THIRD_PARTY],
        //   key: DIRECT_AND_THIRD_PARTY,
        //   dataKey: DIRECT_AND_THIRD_PARTY,
        //   active: false,
        //   path: "/reports/directAndThirdParty",
        //   component: MainPage,
        //   display: modules.includes(LABEL[DIRECT_AND_THIRD_PARTY]),
        // },
        {
          title: LABEL[INCIDENT_REPORTS],
          key: INCIDENT_REPORTS,
          dataKey: INCIDENT_REPORTS,
          active: false,
          path: "/reports/incidentReports",
          component: MainPage,
          display: modules.includes(LABEL[INCIDENT_REPORTS]),
        },
        {
          title: LABEL[DAILY_REPORTS],
          key: DAILY_REPORTS,
          dataKey: DAILY_REPORTS,
          active: false,
          path: "/reports/dailyReports",
          component: MainPage,
          display: modules.includes(LABEL[DAILY_REPORTS]),
        },
        {
          title: LABEL[CUSTOMER_REPORTS],
          key: CUSTOMER_REPORTS,
          dataKey: CUSTOMER_REPORTS,
          active: false,
          path: "/reports/customerReports",
          component: MainPage,
          display: modules.includes(LABEL[CUSTOMER_REPORTS]),
          imageUrl:
            "https://img.freepik.com/free-vector/online-community_24877-50878.jpg?w=826&t=st=1667325054~exp=1667325654~hmac=bc13ecb8ddd30d7938bc1f7954a32b6d86416b189780def82834c4f4604800c7",
        },
        {
          title: LABEL[DISCOUNT_OTHERS],
          key: DISCOUNT_OTHERS,
          dataKey: DISCOUNT_OTHERS,
          active: false,
          path: "/reports/analyticsDiscounts",
          component: MainPage,
          display: modules.includes(LABEL[DISCOUNT_OTHERS]),
        },
        {
          title: LABEL[MONTHLY_SALES_SUMMARY],
          key: MONTHLY_SALES_SUMMARY,
          dataKey: MONTHLY_SALES_SUMMARY,
          active: false,
          path: "/reports/monthlySalesSummary",
          display: modules.includes(LABEL[MONTHLY_SALES_SUMMARY]),
          imageUrl:
            "https://img.freepik.com/free-vector/customer-relationship-management-concept-illustration_114360-7652.jpg?w=826&t=st=1667325236~exp=1667325836~hmac=8469fcf07fee2b9ef7fd859f14eab5d1751aea43eee9399d34f65fc7e398807b",
        },
      ],
    },
    {
      title: LABEL[MASTER_DATA],
      key: MASTER_DATA,
      dataKey: MASTER_DATA,
      Icon: <SettingOutlined />,
      active: false,
      display: modules.includes(LABEL[MASTER_DATA]),
      path: `/${MASTER_DATA}`,
      component: MainPage,
      imageUrl: "",
      subMenu: [
        {
          title: LABEL[USER_MASTERFILE],
          key: USER_MASTERFILE,
          dataKey: USER_MASTERFILE,
          active: false,
          path: "/masterData/userMasterFile",
          component: MainPage,
          display: modules.includes(LABEL[USER_MASTERFILE]),
          imageUrl:
            "https://img.freepik.com/free-vector/follow-me-social-business-theme-design_24877-50426.jpg?w=826&t=st=1667325373~exp=1667325973~hmac=910760e59d76e03872ecc0fbb54a05441395b659f53a1d65b028e3a30509d07d",
        },
        {
          title: LABEL[BRANCH_MASTERFILE],
          key: BRANCH_MASTERFILE,
          dataKey: BRANCH_MASTERFILE,
          active: false,
          path: "/masterData/branchMasterFile",
          component: MainPage,
          display: modules.includes(LABEL[BRANCH_MASTERFILE]),
          imageUrl:
            "https://img.freepik.com/free-vector/location_53876-59940.jpg?w=826&t=st=1667325314~exp=1667325914~hmac=cc9c54f1dab840feb53514b0c1e3c53198cf5b7d1cfd0423e65a7f3366d3cd0e",
        },
        {
          title: LABEL[DROPDOWN_MASTERFILE],
          key: DROPDOWN_MASTERFILE,
          dataKey: DROPDOWN_MASTERFILE,
          active: false,
          path: "/masterData/dropdownMasterfile",
          component: MainPage,
          display: modules.includes(LABEL[DROPDOWN_MASTERFILE]),
          imageUrl:
            "https://img.freepik.com/free-vector/businessman-holding-pencil-big-complete-checklist-with-tick-marks_1150-35019.jpg?w=1380&t=st=1667325693~exp=1667326293~hmac=b92cba32aaa4bfa658f276e42fc256a0302679382db975b5ab1c8dd19ce8c949",
        },
        {
          title: LABEL[ROLES_MASTERFILE],
          key: ROLES_MASTERFILE,
          dataKey: ROLES_MASTERFILE,
          active: false,
          path: "/masterData/rolesMasterfile",
          component: MainPage,
          display: modules.includes(LABEL[ROLES_MASTERFILE]),
          imageUrl:
            "https://img.freepik.com/free-vector/business-team-putting-together-jigsaw-puzzle-isolated-flat-vector-illustration-cartoon-partners-working-connection-teamwork-partnership-cooperation-concept_74855-9814.jpg?w=1380&t=st=1667325631~exp=1667326231~hmac=f1c5f10ebcea0aca70b248f0b2082f3e886008d58bf5d1350edbc783354b2a34",
        },
        {
          title: LABEL[PRODUCTS_MASTERFILE],
          key: PRODUCTS_MASTERFILE,
          dataKey: PRODUCTS_MASTERFILE,
          active: false,
          path: "/masterData/productMasterfile",
          component: MainPage,
          display: modules.includes(LABEL[PRODUCTS_MASTERFILE]),
          imageUrl:
            "https://img.freepik.com/free-vector/barbecue-food-accessories-flat-icons-set_1284-17109.jpg?w=826&t=st=1667325566~exp=1667326166~hmac=520f5ad9f1cf5b8a04fc16e17bd9f171c798de7f138a813112d73da079af93c2",
        },
        {
          title: LABEL[PRICE_HISTORY_MASTERFILE],
          key: PRICE_HISTORY_MASTERFILE,
          dataKey: PRICE_HISTORY_MASTERFILE,
          active: false,
          path: "/masterData/priceHistoryMasterfile",
          component: MainPage,
          display: modules.includes(LABEL[PRICE_HISTORY_MASTERFILE]),
        },
      ],
    },
  ]
}
